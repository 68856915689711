import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import QRCode from "qrcode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { Collapse, Divider, makeStyles } from "@material-ui/core";
import { useInView } from "react-intersection-observer";
import { color, motion } from "framer-motion";

import SEO from "../../components/SEO";
import Link from "../../components/v2/Link";
import LazyImage from "../../components/v4/LazyLoadImage/LazyImage";
import FaqSection from "../../components/v4/FaqSection/FaqSection";
import TruestedSection from "../../components/v4/TrustedSection/TruestedSection";
import TestimonialSlider from "../../components/v3/TestimonialSlider/TestimonialSlider";

import spark2 from "../../images/v4/whatsappPage/icons/spark2.svg";
import spark3 from "../../images/v4/whatsappPage/icons/spark3.svg";
import spark4 from "../../images/v4/whatsappPage/icons/spark4.svg";
import whiteCheckCircle from "../../images/v4/whatsappPage/icons/whiteCheckCircle.svg";
import greenCheckCircle from "../../images/v4/whatsappPage/icons/greenCheckCircle.svg";
import linkGeneartionBackground from "../../images/v4/linkGeneration/images/linkGenerationBackground.png";
import partnerIcon1 from "../../images/v4/aiPage/icons/partnerIcon1.png";
import partnerIcon2 from "../../images/v4/aiPage/icons/partnerIcon2.png";
import partnerIcon3 from "../../images/v4/aiPage/icons/partnerIcon3.png";
import partnerIcon5 from "../../images/v4/aiPage/icons/partnerIcon5.png";
import partnerIcon6 from "../../images/v4/aiPage/icons/partnerIcon6.png";
import partnerIcon7 from "../../images/v4/aiPage/icons/partnerIcon7.png";
import partnerIcon8 from "../../images/v4/aiPage/icons/partnerIcon8.png";
import experienceImg1 from "../../images/v4/qrGeneration/images/experienceImg1.png";
import downArrow from "../../images/v4/aiPage/icons/downArrow.svg";
import whatsappBusinessPartner from "../../images/v3/singaporeCrmHomepage/whatsappBusinessPartner.webp";
import metaBusinessPartner from "../../images/v3/singaporeCrmHomepage/metaBusinessPartner.webp";
import whatsAppFeatureIcon from "../../images/v4/homepage/icons/whatsAppFearureIcon.svg";

import heroSectionBackground from "../../images/v4/qrGeneration/images/heroSectionBackground.png";
import heroSectionImage from "../../images/v4/qrGeneration/images/heroSectionImage.png";
import qrCodeImage from "../../images/v4/qrGeneration/images/qrCodeImage.png";
import quickGuideBackground from "../../images/v4/linkGeneration/images/quickGuideBackground.png";
import experienceContainerBackground from "../../images/v4/linkGeneration/images/experienceContainerBackground.png";
import transformBackgroundImage from "../../images/v4/linkGeneration/images/transformBackgroundImage.png";
import partnerSectionBackground from "../../images/v4/linkGeneration/images/partnerSectionBackground.png";
import quickGuideImage from "../../images/v4/qrGeneration/images/quickGuideImage.png";
import instantChatBg1 from "../../images/v4/qrGeneration/images/instantChatBg1.png";
import instantChatBg2 from "../../images/v4/qrGeneration/images/instantChatBg2.png";
import instantChatBg3 from "../../images/v4/qrGeneration/images/instantChatBg3.png";
import instantChatIcon1 from "../../images/v4/qrGeneration/icons/instantChatIcon1.svg";
import instantChatIcon2 from "../../images/v4/qrGeneration/icons/instantChatIcon2.svg";
import instantChatIcon3 from "../../images/v4/qrGeneration/icons/instantChatIcon3.svg";
import growingIcon1 from "../../images/v4/qrGeneration/icons/growingIcon1.svg";
import growingIcon2 from "../../images/v4/qrGeneration/icons/growingIcon2.svg";
import growingIcon3 from "../../images/v4/qrGeneration/icons/growingIcon3.svg";
import growingIcon4 from "../../images/v4/qrGeneration/icons/growingIcon4.svg";
import growingIcon5 from "../../images/v4/qrGeneration/icons/growingIcon5.svg";
import growingIcon6 from "../../images/v4/qrGeneration/icons/growingIcon6.svg";
import growingIcon7 from "../../images/v4/qrGeneration/icons/growingIcon7.svg";
import downloadIcon from "../../images/v4/qrGeneration/icons/downloadIcon.svg";
import benifitImage from "../../images/v4/qrGeneration/images/benifitImage.png";
import benefitBgImage from "../../images/v4/qrGeneration/images/benefitBgImage.png";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  whatsappSection: {
    backgroundImage: `url(${heroSectionBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: "50px",
    "& $aiSectionLeft": {
      width: "50%",
      gap: "25px",
    },

    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
      marginTop: "70px",
    },
    "@media only screen and (max-width: 600px)": {
      "& $aiSectionLeft": {
        width: "100%",
      },
      paddingTop: "0px",
      "& .centerContainer": {
        width: "100%",
        marginTop: "30px",
      },
      "& .buttonContaienr": {
        "& .primaryButton": {
          width: "100%",
        },
      },
    },
  },
  aiSectionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  aiSectionLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "& .highlighterText": {
      background: "linear-gradient(90deg, #7AFFAB 0%, #80C8FF 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  aiSectionRight: {
    width: "40%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  partnerSlide: {
    width: "185px !important",
    "& img": {
      width: "185px",
      filter: "brightness(0.1)",
    },
    "@media only screen and (max-width: 600px)": {
      width: "140px !important",
      marginRight: "30px !important",
      "& img": {
        width: "140px",
        filter: "brightness(0.1)",
      },
    },
  },
  partnerSlider: {
    padding: "10px 0 80px !important",
    "@media only screen and (max-width: 600px)": {
      paddingBottom: "40px !important",
    },
  },

  listContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
    color: "#A2BED3",
  },
  integrationHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "30px",
    },
  },
  integrationLeft: {
    width: "42%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  integrationRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benefitSection: {
    backgroundImage: `url(${quickGuideBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& $integrationLeft": {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "10px",
    },
    "& $integrationHeader": {
      justifyContent: "space-around",
      alignItems: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& $integrationLeft": {
        width: "100%",
      },
      "& $integrationRight": {
        width: "100%",
      },
    },
  },
  pills: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    marginTop: "25px",
  },
  pill: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px 16px",
    borderRadius: "30px",
    border: "1px solid #02AE7F",
    gap: "10px",
  },
  moreFeatures: {
    "& .header": {
      display: "flex",
      width: "50%",
      gap: "10px",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
    },
    "& .body": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "stretch",
      width: "100%",
      marginTop: "50px",
    },
    "@media only screen and (max-width: 600px)": {
      "& .header": {
        width: "100%",
      },
      "& .body": {
        flexDirection: "column",
        flexWrap: "wrap",
        rowGap: "15px",
      },
    },
    "@media only screen and (min-width: 1900px)": {
      "& .container": {
        paddingTop: "20px",
      },
    },
  },
  featureContainer: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "32%",
    gap: "20px",
    // boxShadow: "0px 0px 16px #C1D7DB66",
    borderRadius: "16px",
    background: "#fff",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    "& p": {
      width: "90%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& p": {
        width: "100%",
      },
    },
  },
  experienceContainer: {
    paddingTop: "300px",
    backgroundImage: `url(${experienceContainerBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& .centerContainer": {
      width: "70%",
      margin: "0 auto",
      textAlign: "center",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      paddingTop: "500px",
    },
    "@media only screen and (min-width: 1900px)": {
      paddingTop: "400px",
    },
  },
  experienceInnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  experienceContainerLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      marginTop: "30px",
    },
  },
  downArrow: {
    transition: "0.3s ease",
    "&.active": {
      transform: "rotate(180deg)",
    },
  },
  body: {
    textAlign: "left",
    margin: "10px 0 0 ",
    "& img": {
      display: "none",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        display: "block",
        width: "100%",
        marginTop: "15px",
      },
    },
  },
  leftNavigation: {
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "150px",
      },
    },
  },
  experienceContainerRight: {
    width: "40%",
    minHeight: "450px",
    "& img": {
      width: "100%",
      height: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      // display: "none",
      width: "100%",
      minHeight: "auto",
    },
  },
  faqBox: {
    borderBottom: "1px solid #C4E4DC",
    paddingBottom: "14px",
    position: "relative",
    transition: "0.3s ease",
    "&::after": {
      left: "0",
      bottom: "-2px",
      width: "0",
      height: "3px",
      content: '""',
      position: "absolute",
      transition: "0.3s ease",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #25D366 0%, #08B57B 66%, #72E9B2 100%)",
    },
    "&.active::after": {
      transition: "5.5s ease",
      width: "100%",
    },
  },
  faqHeader: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  experienceContainerHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "& h2": {
      width: "50%",
    },
    "& p": {
      width: "50%",
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
      "& h2": {
        width: "100%",
      },
      "& p": {
        width: "100%",
      },
    },
  },
  supportSystem: {
    backgroundImage: `url(${linkGeneartionBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: "350px",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      width: "60%",
      margin: "0 auto",
      textAlign: "center",
      "& p": {
        width: "80%",
      },
      "& img": {
        width: "100%",
        maxWidth: "56px",
        position: "relative",
        top: "10px",
      },
      "& h2": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      borderRadius: "0",
      "& .centerContainer": {
        width: "100%",
        "& img": {
          maxWidth: "25px !important",
        },
      },
    },
    "@media only screen and (max-width: 1200px)": {
      "& .centerContainer": {
        "& img": {
          maxWidth: "40px !important",
        },
      },
    },
  },
  broadcastLeftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "60%",
    gap: "32px",
    textAlign: "left",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  broadcastRightContainer: {
    width: "40%",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
  },
  broadcastImg: {
    "& img": {
      width: "100%",
      position: "relative",
      bottom: "0",
    },
  },
  supportBox: {
    top: "100%",
    marginTop: "60px",
    width: "100%",
    display: "flex",
    padding: "40px",
    position: "absolute",
    backgroundImage: `url(${benefitBgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    borderRadius: "20px",
    backdropFilter: "blur(30px)",
    justifyContent: "space-between",
    "& $broadcastLeftContainer": {
      width: "45%",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      width: "94%",
      padding: "15px",
      marginTop: "20px",
      gap: "20px",
    },
  },
  pointers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
  },
  cardPointer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
    "@media only screen and (max-width: 600px)": {
      alignItems: "center",
    },
  },
  commonPageFooterSection: {
    background: "linear-gradient(89deg, #015352 0%, #00926A 100%)",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  centerContainer: {
    marginTop: "80px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "@media only screen and (max-width: 600px)": {
      marginTop: "30px",
    },
  },
  rewards: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    gap: "20px",
    borderRadius: "24px",
    width: "50%",
    padding: "10px 80px",
    background: "#fff",
    "& .border": {
      borderRight: "1px solid #DBDBDB",
      width: "0",
    },
    "& img": {
      width: "60%",
      "@media only screen and (max-width: 600px)": {
        width: "45%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "20px",
    },
  },
  growingBusiness: {
    background: "#2F0056",
    paddingBottom: "320px",
    position: "relative",
    "& .header": {
      width: "55%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
      gap: "32px",
    },
    "& $featureContainer": {
      gap: "20px",
    },
    "@media only screen and (max-width: 600px)": {
      "& .header": {
        width: "100%",
      },
      paddingBottom: "220px",
    },
  },
  broadcastContainerInner: {
    gap: "30px",
    width: "100%",
    display: "flex",
    padding: "0 8rem 0rem 8rem",
    // padding: "0 0rem .5rem 0rem",
    alignItems: "stretch",
    justifyContent: "flex-start",
    "& swiper-wrapper": {
      alignItems: "stretch !important",
    },
    // cursor: "grab",
    // "&::-webkit-scrollbar-track": {
    //   "-webkit-box-shadow": "none",
    //   backgroundColor: "#EDF1F6",
    // },

    // "&::-webkit-scrollbar": {
    //   width: "5px",
    //   height: "5px",
    //   borderRadius: "50%",
    //   backgroundColor: "#EDF1F6",
    // },

    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#D2DBE4",
    //   borderRadius: "50px",
    // },
    "& $featureContainer": {
      width: "31%",
      height: "auto  !important",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "0 15px !important",
      paddingBottom: "10px !important",
    },
    "@media only screen and (min-width: 1900px)": {
      paddingLeft: "23rem !important",
      paddingRight: "23rem !important",
    },
  },
  absoulteCard: {
    position: "absolute",
    margin: "0 auto",
    width: "100%",
    top: "75%",
  },
  stripSection: {
    paddingTop: "280px",
    backgroundImage: `url(${partnerSectionBackground})`,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "& .centerContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media only screen and (max-width: 600px)": {
      paddingTop: "180px",
      "& .centerContainer": {
        textAlign: "center",
      },
    },
  },
  formContainer: {
    padding: "40px 32px",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    width: "50%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "45px",
    "@media only screen and (max-width: 600px)": {
      padding: "20px",
      gap: "30px",
      width: "100%",
    },
  },
  formHeader: {},
  formBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "30px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "25px",
    },
  },
  inputBoxContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    gap: "30px",
    position: "relative",
    "&::before": {
      left: "20px",
      width: "0",
      height: "100%",
      content: '""',
      position: "absolute",
      borderRight: "2px dashed #cccccc",
      top: "40px",
      "@media only screen and (max-width: 600px)": {
        left: "15px",
        top: "30px",
      },
    },
    "&:nth-child(5):before": {
      display: "none",
    },
    "&:nth-child(6):before": {
      display: "none",
    },
    "&:last-child:before": {
      display: "none",
    },
    "&:last-child": {
      "& $formStep": {
        background: "transparent",
      },
    },
    "&:nth-child(6)": {
      "& $formStep": {
        background: "transparent",
      },
    },
    "@media only screen and (max-width: 600px)": {
      gap: "15px",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "15px",
    width: "calc(100% - 70px)",
    "& .primaryButton": {
      width: "100%",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      width: "calc(100% - 45px)",
      gap: "10px",
    },
  },
  inputContainerHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
  },
  inputContainerBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
    width: "100%",
  },
  input: {
    width: "100%",
    border: "1px solid #D9D9D9",
    outline: "none",
    padding: "15px 10px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    WebkitAppearance: "none",
    MozAppearance: "textfield",
    margin: "0",
    justifyContent: "space-between",
    "&::placeholder": {
      color: "#8A8A8A",
    },

    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#EDF1F6",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D2DBE4",
      borderRadius: "50px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      margin: 0,
    },
  },
  formStep: {
    height: "40px",
    minWidth: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#02AE7F",
    borderRadius: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "30px",
      height: "30px",
      minWidth: "30px",
    },
  },
  formFooter: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "57%",
    "& .primaryButton": {
      width: "71%",
      marginLeft: "70px",
    },
  },
  whatsappButton: {
    border: "2px solid #02AE7F",
    background: "transparent",
    padding: "15px 24px",
    borderRadius: "100px",
    cursor: "pointer",
    outline: "none",
    textDecoration: "none",
    color: "#007253",
    display: "flex",
    lineHeight: "1.75 !important",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.3s ease",
    width: "80%",
    fontWeight: "600",
    zIndex: "0",
    gap: "10px",
    textDecoration: "none !important",
    "&:hover": {
      color: "#007253",
    },
    "& img": {
      width: "28px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
  formBoxContainerBox: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
    borderRadius: "25px",
    overflow: "hidden",
    boxShadow: "0px 0px 32px #6DE7C56E",
    marginTop: "60px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  qrContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    height: "auto",
    background: "linear-gradient(180deg, #CEFFEA 0%, #CBE8FF 100%)",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "20px",
    },
  },
  qrContainerInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
    gap: "25px",
    textAlign: "center",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  qrCodeContainer: {
    border: "2px solid #B4DAEB",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    padding: "10px",
    "& img": {
      height: "196px",
      width: "196px",
      borderRadius: "16px",
    },
  },
  downloadQrButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    color: "#007188",
    textDecoration: "underline",
    "&:hover": {
      color: "#007188",
    },
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    gap: "35px",
    "& input": {
      padding: 0,
      height: "0",
      width: "0",
      cursor: "pointer",
      position: "relative",
    },
    "& label": {
      position: "relative",
      cursor: "pointer",
    },
    "& input::before": {
      content: "''",
      backgroundColor: "#fff",
      border: "1px solid #0291AE",
      height: "20px",
      width: "20px",
      borderRadius: "5px",
      background: "#fff",
      display: "inline-block",
      position: "relative",
      verticalAlign: "middle",
      cursor: "pointer",
      marginRight: "5px",
    },
    "& input:checked::after": {
      top: "3px",
      left: "7px",
      width: "5px",
      border: "1px solid #fff",
      height: "12px",
      content: "''",
      position: "absolute",
      transform: "rotate(45deg)",
      borderWidth: "0 2px 2px 0",
    },
    "& input:checked::before": {
      background: "#0291AE",
    },
    "@media only screen and (max-width: 600px)": {
      gap: "25px",
      width: "100%",
    },
    "& a": {
      color: "#208EEC",
      "&:hover": {
        color: "#208EEC",
      },
    },
  },
  checkboxContainerOuter: {
    width: "100%",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
}));

const FreeWhatsappQrCodeGenerator = () => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [nameError, setNameError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [consent, setConsent] = useState(true);
  const [consentError, setConsentError] = useState("");


  const createLead = async () => {
    try {
      const response = await fetch(
        "https://backend.peppercloud.com/api/v2/web-forms/58bafeec-a7dd-4c6e-97c9-88d290c12618/view"
      );
      const data = await response.json();
      const [url, queries] = window.location.href.split("?");
      let queryObj={};
      if (queries) {
        queryObj = qs.parse(queries);

      }
      const payload = {
        form: data.data.uid,
        token: data.data.token,
        records: {
          WF_1: name,
          WF_2: email,
          WF_4: "+" + phoneNumber,
          WF_3: companyName,
        },
        recaptchaToken: null,

        marketingDetails: {
          F143: url,
          F144: queryObj.utm_campaign || "",
          F145: queryObj.utm_source || "",
          F146: queryObj.utm_medium || "",
          F147: queryObj.utm_term || "",
          F148: queryObj.utm_content || "",
          F151: document.title,
        },
      };


      await fetch("https://backend.peppercloud.com/api/v2/web-form-response", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    } catch (error) {

    }
  };
  // Function to validate email
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  // Function to generate WhatsApp link
  const generateWhatsAppLink = () => {
    // Reset error states
    setPhoneError("");
    setEmailError("");
    setCompanyError("");
    setNameError("");
    setConsentError("");

    let hasError = false;
    let scrollOffset = 0;

    // Validate name
    if (!name.trim()) {
      setNameError("Please enter your name");
      hasError = true;
      scrollOffset = -700;
    }

    // Validate phone number
    if (!phoneNumber.trim()) {
      setPhoneError("Please enter WhatsApp number");
      hasError = true;
      if (!scrollOffset) scrollOffset = -500;
    }

    // Validate email
    if (!email.trim()) {
      setEmailError("Please enter email ID");
      hasError = true;
      if (!scrollOffset) scrollOffset = -350;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter correct email ID");
      hasError = true;
      if (!scrollOffset) scrollOffset = -350;
    }

    // Validate companyName
    if (!companyName.trim()) {
      setCompanyError("Please enter Company name");
      hasError = true;
      if (!scrollOffset) scrollOffset = -150;
    }

    if (!consent) {
      hasError = true;
      setConsentError("Please check the above checkbox");
    }

    if (hasError) {
      window.scrollBy({
        top: scrollOffset,
        behavior: "smooth",
      });
      return;
    }

    // Format phone number - remove spaces, dashes, and other non-numeric chars
    const formattedPhone = phoneNumber.replace(/\D/g, "");

    // Encode message for URL
    const encodedMessage = encodeURIComponent(message);

    // Create WhatsApp link
    const link = `https://wa.me/${formattedPhone}?text=${encodedMessage}`;

    // Generate QR code
    QRCode.toDataURL(link)
      .then((url) => {
        setQrCode(url);
      })
      .catch((err) => {
        console.error(err);
      });
    if (!hasError) {
      const isMobile = window.innerWidth <= 600;
      window.scrollBy({
        top: isMobile ? 400 : -400,
        behavior: "smooth",
      });
    }
    createLead();
  };

  const partners = [
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
  ];
  const featureList = [
    {
      icon: instantChatIcon1,
      background: instantChatBg1,
      heading: "Why use WhatsApp QR codes?",
      text: "Save your customers the trouble of manually typing contact numbers. Provide QR code to quickly scan and connect with you in seconds.",
    },
    {
      icon: instantChatIcon2,
      background: instantChatBg2,
      heading: "How do QR codes work?",
      text: "When scanned, the QR code directly opens a WhatsApp chat with your business, with a pre-filled conversation starter message.",
    },
    {
      icon: instantChatIcon3,
      background: instantChatBg3,
      heading: "How to use QR code generator?",
      text: "Just enter your WhatsApp number, add an optional custom message, generate your QR code, and share it across digital and offline channels.",
    },
  ];
  const growingList = [
    {
      icon: growingIcon1,
      background: "#F9F1FF",
      heading: "All-in-one platform",
      text: "Capture leads, manage deals, automate workflows, and communicate seamlessly across multiple channels from a central dashboard.",
    },
    {
      icon: growingIcon2,
      background: "#FFF5E8",
      heading: "Manage client interactions",
      text: "Enable customers to initiate chat through QR code and automatically capture and track conversations within CRM.",
    },
    {
      icon: growingIcon3,
      background: "#F4FAFF",
      heading: "AI-powered automation",
      text: "Respond to queries with AI chatbot, automate chatflows, streamline lead assignments, and gain AI-driven insights.",
    },
    {
      icon: growingIcon7,
      background: "#FFF5F1",
      heading: "Analytics & reporting",
      text: "Track performance with real-time reports on sales, customer engagement, and business growth.",
    },
    {
      icon: growingIcon4,
      background: "#F1FFF9",
      heading: "Customisation & integrations",
      text: "Adapt Pepper Cloud CRM to your business needs with flexible configurations and integrations.",
    },
    {
      icon: growingIcon6,
      background: "#FFF5E8",
      heading: "Security & support",
      text: "Keep data secure with encryption, access controls, and compliance measures, backed by 24/7 expert support.",
    },
  ];
  const experienceList = [
    {
      title: "Retail and e-commerce",
      alt: "WhatsApp link generator for retail and eCommerce",
      desc: "Place QR codes on product packaging, invoices, and store displays to offer instant customer support, order tracking, and personalised shopping assistance.",
    },
    {
      title: "Restaurants and Cafés",
      alt: "WhatsApp QR code generator for resturant",
      desc: "Simplify table reservations, takeaway orders, and customer feedback by adding WhatsApp QR codes to menus, receipts, or in-store posters.",
    },
    {
      title: "Education and coaching",
      alt: "WhatsApp QR code generator for education",
      desc: "Enable students and parents to inquire about courses, schedules, and admissions. QR codes on brochures, social media, and websites make communication effortless.",
    },
    {
      title: "Real estate",
      alt: "WhatsApp QR code generator for real estate",
      desc: "Let potential buyers or tenants scan a QR code on property listings, banners, or brochures to instantly connect with agents for inquiries, site visits, and consultations.",
    },
  ];

  const faqs = [
    {
      question: "What is a WhatsApp QR code generator?",
      answer:
        "A WhatsApp QR code generator creates a scannable QR code that instantly opens a WhatsApp chat with your business, eliminating the need to manually save contact numbers.",
    },
    {
      question: "How does a WhatsApp QR code generator work?",
      answer:
        "When a customer scans the QR code, it redirects them to a WhatsApp chat with your business. You can also add a pre-filled message to guide the conversation.",
    },
    {
      question: "Why should I use a WhatsApp QR code generator?",
      answer:
        "It simplifies customer interactions, speeds up response times, and makes it easier for businesses to connect with potential leads across multiple channels.",
    },
    {
      question: "Can I customise my WhatsApp QR code?",
      answer:
        "Yes, you can add a pre-filled message to prompt customers on what to say when they start a conversation with you.",
    },
    {
      question: "Where can I share my WhatsApp QR code?",
      answer:
        "You can place it on your website, social media, product packaging, business cards, posters, emails, store entrances, and marketing materials to drive engagement.",
    },
    {
      question: "Is Pepper Cloud's WhatsApp QR code generator free to use?",
      answer:
        "Yes, Pepper Cloud's free WhatsApp QR code generator allows you to create unlimited QR codes at no cost.",
    },
    {
      question: "Can I generate multiple QR codes for different campaigns?",
      answer:
        "Yes! You can create different QR codes for various products, promotions, and customer support needs.",
    },
    {
      question: "Do WhatsApp QR codes expire?",
      answer:
        "No, once generated, your WhatsApp QR code remains active indefinitely.",
    },
    {
      question: "Is it possible to include a message with my WhatsApp QR code?",
      answer:
        "Yes, you can add a customised pre-filled message to help customers start the conversation effortlessly.",
    },
    {
      question: "How can WhatsApp QR codes help in lead generation?",
      answer:
        "By placing QR codes on ads, brochures, packaging, and digital campaigns, you can encourage direct WhatsApp inquiries, increasing lead capture and conversions.",
    },
    {
      question: "How to add WhatsApp QR on Instagram, Facebook, and Twitter?",
      answer:
        "You can upload your WhatsApp QR code as a profile image, post it as a story, or include it in your bio and posts to make it easy for customers to reach you instantly.",
    },
  ];

  const [activeAccordian, setActiveAccordian] = useState(0);

  const { ref, inView } = useInView({
    threshold: 0.5, // adjust as needed
    triggerOnce: false,
  });

  useEffect(() => {
    let timer;
    if (inView) {
      timer = setInterval(() => {
        setActiveAccordian((prev) => (prev + 1) % experienceList.length);
      }, 5500);
    }
    // Clear the timer when the component unmounts or when inView changes.
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [inView, experienceList.length]);

  const scrollToSection = () => {
    document.getElementById("fromSection").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <main>
      <SEO
        title={"Free WhatsApp QR Code Generator | Instant Chat with One Scan"}
        pathname={
          "https://peppercloud.com/whatsapp-crm-features/free-whatsapp-qr-code-generator/"
        }
        keywords="whatsapp qr code generator,whatsapp qr code​, create whatsapp qr code​,generate whatsapp qr code,whatsapp qr code scan​, generate qr code for whatsapp"
        description={
          "Create a free WhatsApp QR code with Pepper Cloud and let customers connect with you instantly. Scan, chat, and boost engagement—no saved numbers needed!"
        }
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
      />
      <section className={classes.whatsappSection}>
        <div className="container">
          <div className={classes.aiSectionContainer}>
            <div className={classes.aiSectionLeft}>
              <motion.h1
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-18"
                style={{ color: "#97FFE3" }}
              >
                Free WhatsApp QR code generator
              </motion.h1>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 font-white"
              >
                Turn scans into sales with a WhatsApp QR code
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18"
                style={{ color: "#A2BED3" }}
              >
                Simplify how your customers initiate conversations with you.
                Generate a WhatsApp QR code for free, share across channels, and
                let users reach you instantly from anywhere.
              </motion.p>
              <div className={classes.listContainer}>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    One-click WhatsApp access for customers
                  </p>
                </div>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    Customisable conversation starter
                  </p>
                </div>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    100% free, quick, and easy to use
                  </p>
                </div>
              </div>
              <div className="buttonContaienr">
                <button
                  onClick={() => scrollToSection()}
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className=" primaryButton font-wix-medium font-16 font-white"
                >
                  Generate my WhatsApp QR code
                </button>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={classes.aiSectionRight}
            >
              <LazyImage
                source={heroSectionImage}
                width={"100%"}
                alt={"Free WhatsApp QR code generator"}
              />
            </motion.div>
          </div>
          <div className={classes.centerContainer}>
            <div className={classes.rewards}>
              <img
                src={whatsappBusinessPartner}
                alt="WhatsApp Business Partner"
              />
              <div className="border" />
              <img src={metaBusinessPartner} alt="Meta Business Partner" />
            </div>
          </div>
        </div>
      </section>
      <section className={clsx(classes.benefitSection)}>
        <div className="container">
          <div className={classes.integrationHeader}>
            <div className={classes.integrationLeft}>
              <motion.div
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="sectionTag"
              >
                <p className="font-18 font-wix-semibold color-blue1">
                  Quick guide
                </p>
              </motion.div>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 color-black1"
              >
                What is a WhatsApp QR code?
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-black2 mt20"
              >
                A WhatsApp QR code is a scannable barcode that lets customers
                instantly open a WhatsApp conversation with your business. By
                simply scanning the code with their smartphone, customers can
                reach you without needing to manually save or enter phone
                numbers, streamlining communication and improving their overall
                experience.
              </motion.p>
              <div className={classes.pills}>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    100% Free
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    Scan to chat
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    Easy setup
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    Share anywhere
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.integrationRight}>
              <LazyImage
                source={quickGuideImage}
                alt={"What is WhatsApp QR code generator?"}
                width={"100%"}
                className={classes.integrationRightImage}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={classes.moreFeatures}>
        <div className="container">
          <div className="header">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 color-black1"
            >
              From offline to online - connect instantly with QR codes
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black1"
            >
              Enable instant customer interactions and streamline support. A
              quick scan lets customers connect with your business on WhatsApp,
              driving engagement and higher conversions.
            </motion.p>
          </div>
          <div className="body">
            {featureList.map((feature) => (
              <div
                className={classes.featureContainer}
                style={{ background: `url(${feature.background})` }}
              >
                <LazyImage
                  source={feature.icon}
                  alt={feature.heading}
                  className={classes.featureIcon}
                  // alt={listItem.alt}
                />
                <p className="font-wix-semibold font-24 font-white">
                  {feature.heading}
                </p>
                <p className="font-wix-mediu font-18 font-white">
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="fromSection" className={classes.supportSystem}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              How to create a <br />{" "}
              <img alt="WhatsApp" src={whatsAppFeatureIcon} /> WhatsApp QR code?
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 font-white"
            >
              With Pepper Cloud’s free WhatsApp QR code generator, you can
              create a customised WhatsApp QR code in just a few clicks.
            </motion.p>
          </div>
          <div className={classes.formBoxContainerBox}>
            <div className={classes.formContainer}>
              <div className={classes.formHeader}>
                <p className="font-28 font-wix-semibold font-black1">
                  Follow these simple steps to generate your WhatsApp QR code
                </p>
              </div>
              <div className={classes.formBody}>
                <div className={classes.inputBoxContainer}>
                  <div
                    className={clsx(
                      classes.formStep,
                      "font-18 font-wix-semibold font-white"
                    )}
                  >
                    1
                  </div>
                  <div className={classes.inputContainer}>
                    <div className={classes.inputContainerHeader}>
                      <p className="font-22 font-wix-semibold font-black1">
                        Enter your name
                      </p>
                    </div>
                    <div className={classes.inputContainerBody}>
                      <label className="font-16 font-wix-medium font-black1">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className={clsx(
                          "font-18 font-wix-regular font-black1",
                          classes.input
                        )}
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      {nameError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {nameError}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.inputBoxContainer}>
                  <div
                    className={clsx(
                      classes.formStep,
                      "font-18 font-wix-semibold font-white"
                    )}
                  >
                    2
                  </div>
                  <div className={classes.inputContainer}>
                    <div className={classes.inputContainerHeader}>
                      <p className="font-22 font-wix-semibold font-black1">
                        Enter your WhatsApp number
                      </p>
                    </div>
                    <div className={classes.inputContainerBody}>
                      <label className="font-16 font-wix-medium font-black1">
                        WhatsApp number (with country code & without +){" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        className={clsx(
                          "font-18 font-wix-regular font-black1",
                          classes.input
                        )}
                        value={phoneNumber}
                        maxLength={15}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, "");
                          setPhoneNumber(value);
                        }}
                        placeholder="Enter WhatsApp number"
                      />
                      {phoneError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {phoneError}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.inputBoxContainer}>
                  <div
                    className={clsx(
                      classes.formStep,
                      "font-18 font-wix-semibold font-white"
                    )}
                  >
                    3
                  </div>
                  <div className={classes.inputContainer}>
                    <div className={classes.inputContainerHeader}>
                      <p className="font-22 font-wix-semibold font-black1">
                        Enter your email ID
                      </p>
                    </div>
                    <div className={classes.inputContainerBody}>
                      <label className="font-16 font-wix-medium font-black1">
                        Email ID <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className={clsx(
                          "font-18 font-wix-regular font-black1",
                          classes.input
                        )}
                        placeholder="Enter your email ID"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        required
                      />
                      {emailError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {emailError}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.inputBoxContainer}>
                  <div
                    className={clsx(
                      classes.formStep,
                      "font-18 font-wix-semibold font-white"
                    )}
                  >
                    4
                  </div>
                  <div className={classes.inputContainer}>
                    <div className={classes.inputContainerHeader}>
                      <p className="font-22 font-wix-semibold font-black1">
                        Enter your company name
                      </p>
                    </div>
                    <div className={classes.inputContainerBody}>
                      <label className="font-16 font-wix-medium font-black1">
                        Company name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className={clsx(
                          "font-18 font-wix-regular font-black1",
                          classes.input
                        )}
                        value={companyName}
                        required
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Enter your company name"
                      />
                      {companyError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {companyError}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.inputBoxContainer}>
                  <div
                    className={clsx(
                      classes.formStep,
                      "font-18 font-wix-semibold font-white"
                    )}
                  >
                    5
                  </div>
                  <div className={classes.inputContainer}>
                    <div className={classes.inputContainerHeader}>
                      <p className="font-22 font-wix-semibold font-black1">
                        Custom message (Optional)
                      </p>
                    </div>
                    <div className={classes.inputContainerBody}>
                      <label className="font-16 font-wix-medium font-black1">
                        Pre-filled message
                      </label>
                      <textarea
                        className={clsx(
                          "font-18 font-wix-regular font-black1",
                          classes.input
                        )}
                        placeholder="Enter a conversation starter. Eg. Hello, I have a question about your service. Can you please help me?"
                        rows={5}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{ resize: "none" }}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.inputBoxContainer}>
                  <div className={classes.formStep}></div>
                  <div className={classes.inputContainer}>
                    <div className={classes.checkboxContainerOuter}>
                      <div className={classes.checkboxContainer}>
                        <input
                          type="checkbox"
                          id="check"
                          checked={consent}
                          onClick={() => {
                            setConsent(!consent);
                          }}
                        />
                        <label
                          for="check"
                          onClick={() => {
                            setConsent(!consent);
                          }}
                          className="font-16 font-wix-medium font-black1"
                        >
                          I confirm and agree to the storing and processing of
                          my personal data as described in the{" "}
                          <a
                            target="_blank"
                            href="https://peppercloud.com/privacy"
                          >
                            Privacy Statement
                          </a>
                          .
                        </label>
                      </div>
                      {consentError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {consentError}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.inputBoxContainer}>
                  <div className={classes.formStep}></div>
                  <div className={classes.inputContainer}>
                    <button
                      className="primaryButton font-18 font-wix-medium font-white"
                      onClick={generateWhatsAppLink}
                    >
                      Generate my WhatsApp QR Code
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.qrContainer}>
              <div className={classes.qrContainerInner}>
                <motion.p
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="font-24 font-wix-semibold font-black1"
                >
                  {qrCode
                    ? "Here is your WhatsApp QR code"
                    : "Your WhatsApp QR Code will be shown here"}
                </motion.p>

                <div className={classes.qrCodeContainer}>
                  <img
                    src={qrCode ? qrCode : qrCodeImage}
                    alt="WhatsApp QR Code"
                  />
                </div>

                {qrCode && (
                  <a
                    href={qrCode}
                    download="whatsapp-qr-code.png"
                    className={classes.downloadQrButton}
                  >
                    <img src={downloadIcon} />
                    Download QR Code
                  </a>
                )}
              </div>
            </div>
          </div>

          <motion.div
            initial={{ opacity: 1, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className={classes.supportBox}
            // style={{ background: point.background }}
          >
            <div className={classes.broadcastLeftContainer}>
              <p className="font-40 font-wix-regular font-white">
                Achieve more customer connections with WhatsApp QR code
              </p>
              <div className={classes.pointers}>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Share QR code on website, social media channels, and more to
                    enable customers to connect with you from anywhere.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Increase offline engagement by adding QR codes to marketing
                    materials, packaging, and business signage.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Capture high-quality leads effortlessly as potential
                    customers initiate conversations directly via WhatsApp.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Boost conversion rates by providing instant access to sales
                    and support, accelerating the buying decision process.
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.broadcastRightContainer}>
              <LazyImage
                source={benifitImage}
                alt={"Benefits of WhatsApp QR code generator"}
                className={classes.broadcastImg}
              />
            </div>
          </motion.div>
        </div>
      </section>
      <section className={classes.experienceContainer} ref={ref}>
        <div className="container">
          <div className={classes.experienceContainerHeader}>
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 text-black1"
            >
              One scan, instant connection, endless possibilities
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black1"
            >
              Explore the application and use cases of WhatsApp QR codes across
              industries. Simplify customer interactions and boost conversions
              with instant WhatsApp access.
            </motion.p>
          </div>
          <div className={classes.experienceInnerContainer}>
            <div className={classes.experienceContainerLeft}>
              {experienceList.map((faq, index) => (
                <motion.div
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  transition={{ duration: 1, delay: index * 0.01 }}
                  className={clsx(
                    classes.faqBox,
                    index === activeAccordian && "active"
                  )}
                  onClick={() => setActiveAccordian(index)}
                >
                  <div className={classes.faqHeader}>
                    <h3 className="font-20 font-wix-semibold">{faq.title}</h3>
                    <img
                      src={downArrow}
                      alt="Arrow"
                      className={clsx(
                        classes.downArrow,
                        index === activeAccordian && "active"
                      )}
                    />
                  </div>
                  <Collapse
                    className={classes.body}
                    in={activeAccordian === index}
                  >
                    <p className="font-18 font-wix-regular color-black2">
                      {faq.desc}
                    </p>
                    {/* <motion.img
                      initial={{ opacity: 1, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      src={experienceImg1}
                      alt={faq.alt}
                    /> */}
                  </Collapse>
                </motion.div>
              ))}
            </div>
            <div className={classes.experienceContainerRight}>
              <motion.img
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                src={experienceImg1}
                alt={"WhatsApp QR code generator for real estate"}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={classes.growingBusiness}>
        <div className="container">
          <div className="header">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              Why Pepper Cloud is the best choice for growing businesses
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 font-white"
            >
              Pepper Cloud offers a comprehensive, AI-powered CRM solution that
              simplifies your sales processes, customer communication, and
              overall business management
            </motion.p>
            <Link
              className="primaryButton font-20 font-wix-medium font-white"
              to="/contact"
            >
              Book a free demo
            </Link>
          </div>
        </div>
        <Swiper
          slidesPerView={3.3}
          // centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          loop={false}
          speed={5000}
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          // freeMode={true}
          className={classes.broadcastContainerInner}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              slidesPerView: 1.3,
              slidesPerGroup: 1,
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              centeredSlides: false,
              spaceBetween: 10,
            },
            // For larger screens (desktop)
            768: {
              slidesPerView: 3.3,
              centeredSlides: false,
              spaceBetween: 30,
              grabCursor: true,
            },
          }}
        >
          {growingList.map((feature) => (
            <SwiperSlide
              className={classes.featureContainer}
              style={{ background: feature.background }}
            >
              <LazyImage
                source={feature.icon}
                alt={feature.heading}
                className={classes.featureIcon}
                // alt={listItem.alt}
              />
              <p className="font-wix-semibold font-24 color-black1">
                {feature.heading}
              </p>
              <p className="font-wix-mediu font-18 color-black1">
                {feature.text}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classes.absoulteCard}>
          <TruestedSection background={transformBackgroundImage} />
        </div>
      </section>
      <section className={classes.stripSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h3
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-24 font-black1"
            >
              Best CRM with WhatsApp integration - Trusted by SMEs globally
            </motion.h3>
          </div>
        </div>
        <Swiper
          className={classes.partnerSlider}
          slidesPerView="auto"
          spaceBetween={60}
          loop={true}
          speed={2000}
          modules={[Autoplay]}
          allowTouchMove={false}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              spaceBetween: 20,
            },
            // For larger screens (desktop)
            768: {
              freeMode: false,
              autoplay: {
                delay: 100,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              },
              speed: 2000,
              loop: true,
              allowTouchMove: true,
            },
          }}
        >
          {partners.map((partner) => (
            <SwiperSlide className={classes.partnerSlide}>
              <img src={partner.icon} alt={partner.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <TestimonialSlider
        noButton={true}
        heading={"Real experiences. Real growth."}
        desc={
          "See how businesses are enhancing customer interactions with Pepper Cloud."
        }
      />
      <div className={classes.wpFaq}>
        <FaqSection
          backgroundColor={"#F1FFF9"}
          heading={"FAQ - Pepper Cloud free WhatsApp QR code generator"}
          faqs={faqs}
        />
      </div>
      <section className={classes.commonPageFooterSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              Bridge the gap - let customers reach you in a scan!
            </motion.h2>
            <button
              className="primaryButton font-20 font-wix-medium font-white"
              onClick={() => scrollToSection()}
            >
              Generate my WhatsApp QR code
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FreeWhatsappQrCodeGenerator;
